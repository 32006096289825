import React from "react";
import { startCase } from "lodash";

const ContentRow = (props) => (
  <div>
    <div className="resource-list">
      {props.data.map((item) => (
        <a key={item.id} href={item.link} className="resource-card-link mr-3">
          <div className="card resource-card mb-2">
            <div className="card-header">
              <h4 className="resource-title">{startCase(item.title)}</h4>
              <span className="resource-creator">
                by: {startCase(item.creator)}
              </span>
            </div>

            <div className="card-body py-3">
              <div className="resource-description mb-2">
                {item.description}
              </div>
              <div className="resource-type mb-2">
                <i className="fas fa-book"></i> {startCase(item.type)}
              </div>
              <div className="resource-duration mb-2">
                <i className="fas fa-stopwatch"></i> {item.duration}
              </div>
              <div className="resource-rating mb-2">
                <i className="fas fa-thumbs-up"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                &nbsp;(1)
              </div>
            </div>
          </div>
        </a>
      ))}
      
    </div>
  </div>
);

export default ContentRow;
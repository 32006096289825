import React from "react";

export default function Footer() {
  return (
    <div>
      <footer>
        <div className="col-lg-8 col-md-10 mx-auto">
          <ul className="list-inline text-center">
            <li className="list-inline-item">
              <a href="https://twitter.com/knowledgebase2_">
                <span className="fa-stack fa-lg">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>

            <li className="list-inline-item">
              <a href="https://github.com/PopularDemand/knowledgebase2">
                <span className="fa-stack fa-lg">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-github fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
          </ul>

          <p className="copyright text-muted">
            Copyright &copy; knowledgebase2 by{" "}
            <a href="https://popdemtech.com">Popular Demand, LLC</a> 2019
          </p>
        </div>
      </footer>
    </div>
  );
}

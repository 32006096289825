import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";



const NavBar = () => {
  const [ isVisible, setVisible ] = useState(true);
  const history = useHistory();

  const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }

  const handleScroll = (e) => {
    var lastPosition = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener(
      "scroll",
      () => {
        var newPosition =
          window.pageYOffset || document.documentElement.scrollTop;
        if (newPosition > lastPosition) {
          setVisible(false);
        } else {
          setVisible(true);
        }
        lastPosition = newPosition <= 0 ? 0 : newPosition; // For Mobile or negative scrolling
      },
      false
    );
  };


  const handleHome = () => {
    history.push("/");
    scrollTop();
  };
  const handleAbout = () => {
    history.push("/about");
    scrollTop();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top is-fixed ${isVisible ? 'is-visible' : 'is-hidden' }`}
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <span className="logo-mini">
            <span className="logo-text">
              <span className="knowledge">k</span>
              <span className="base-two">b2</span>
            </span>
          </span>
        </a>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarResponsive"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <button className="nav-link nav-button" onClick={handleHome}>
                Home
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link nav-button" onClick={handleAbout}>
                About
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
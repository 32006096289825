import React from "react";

import { Route, HashRouter } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import history from "../../history";

const RouterComponent = () => (
  <HashRouter history={history}>
    <Route path="/" component={Home} exact />
    <Route path="/about" component={About} />
  </HashRouter>
);

export default RouterComponent;

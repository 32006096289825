import React from "react";

export default function Masthead(props) {
  return (
    <div>
      <div>
        <div
          className="masthead"
          style={{
            backgroundImage: `url(${props.background})`,
          }}
        >
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <div className="logo">
                    <h1 className="logo-text">
                      <span className="knowledge">knowledge</span>
                      <span className="base-two">base2</span>
                    </h1>
                  </div>
                  <span className="subheading">{props.subhead}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="value-prop">
        <span className="mb-5">{props.description}</span>
      </div>
    </div>
  );
}

const content = [
    {
      id: 1,
      title: "practical object-oriented design",
      creator: "sandi metz",
      type: "book",
      duration: "1 month",
      rating: 4,
      link: "poodr.pdf",
      tags: "ruby:backend:code quality:intermediate",
      description:
        "Explains object-oriented design using realistic, understandable examples.",
      category: "technology",
    },
    {
      id: 2,
      title: "eloquent javascript",
      creator: "marijn haverbeke",
      type: "book",
      duration: "1 month",
      rating: 3,
      link: "http://eloquentjavascript.net/",
      tags: "frontend:backend:beginner:javascript",
      description:
        "This is a book about JavaScript, programming, and the wonders of the digital.",
      category: "technology",
    },
    {
      id: 3,
      title: "2ality",
      creator: "axel rauschmayer",
      type: "blog",
      duration: "monthly",
      rating: 3,
      link: "https://2ality.com/",
      tags: "quizzes:javascript",
      description:
        "Detailed explanations about the inner workings of JavaScript. Updated at least biweekly.",
      category: "technology",
    },
    {
      id: 4,
      title: "the feynman technique: the best way to learn anything",
      creator: "farnam street",
      type: "article",
      duration: "3 minutes",
      rating: 5,
      link: "https://fs.blog/2012/04/feynman-technique/",
      tags: "learning:mental",
      description:
        "Three step process that can be applied to any subject to solidify understanding.",
      category: "technology",
    },
    {
      id: 5,
      title: "how the internet works",
      creator: "code.org",
      type: "video",
      duration: "45 minutes",
      rating: 4,
      link:
        "https://www.youtube.com/playlist?list=PLzdnOPI1iJNfMRZm5DDxco3UdsFegvuB7",
      tags: "networking:beginner",
      description:
        "2 years in the making, this series of short videos explaining How the Internet Works.",
      category: "technology",
    },
    {
      id: 6,
      title: "learn python the hard way",
      creator: "zed shaw",
      type: "book",
      duration: "1 month",
      rating: 5,
      link: "https://learnpythonthehardway.org/book",
      tags: "python:command line:terminal:beginner",
      description: "Learn Python fundamentals through guided practice.",
      category: "technology",
    },
    {
      id: 7,
      title: "learn to code html & css",
      creator: "shay howe",
      type: "walkthrough",
      duration: "2 weeks",
      rating: 5,
      link: "http://learn.shayhowe.com/html-css/",
      tags: "html:css:beginner:intermediate",
      description:
        "Thorough walkthrough on building a website with HTML5 and CSS.",
      category: "technology",
    },
    {
      id: 8,
      title: "user onboard",
      creator: "samuel hulick",
      type: "walkthrough",
      duration: "10 minutes",
      rating: 3,
      link: "https://www.useronboard.com/user-onboarding-teardowns/",
      tags: "user experience:design",
      description:
        "Step by step UI/UX teardowns of the internet's most popular sites.",
      category: "design",
    },
    {
      id: "9",
      title: "MKBHD",
      creator: "Marques Brownlee",
      type: "vlog",
      duration: "12 minutes",
      rating: 5,
      link: "https://www.youtube.com/user/marquesbrownlee",
      tags: "personality:new tech:electronics:reviews",
      description: "High quality tech reviews of the newest gadgets.",
      category: "technology",
    },
    {
      id: 10,
      title: "learning how to learn",
      creator: "McMaster University, University of California San Diego",
      type: "mooc",
      duration: "8 hours",
      rating: 3,
      link: "https://www.coursera.org/learn/learning-how-to-learn",
      tags: "education:formal:informal",
      description:
        "If you've ever wanted to become better at anything, this course will help serve as your guide.",
      category: "technology",
    },
    {
      id: 11,
      title: "7 Rules for Creating Gorgeous UI",
      creator: "Erik D. Kennedy",
      type: "article",
      duration: "15 minutes",
      rating: 5,
      tags: "user interface:design:beginner",
      link:
        "https://medium.com/@erikdkennedy/7-rules-for-creating-gorgeous-ui-part-1-559d4e805cda",
      description:
        "7 simple rules to adhere to at the beginning of your designer journey.",
      category: "design",
    },
  ];

export default content;
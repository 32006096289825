import React from "react";

import Navbar from "../brand/Navbar";
import Footer from "../brand/Footer";
import aboutImage from "../images/about-us-bg.jpg";

import Masthead from "../brand/Masthead";

export default function About() {
  return (
    <div>
      <Navbar />
      <Masthead
        description='"Knowledge, if inaccessible, is useless."'
        background={aboutImage}
        subhead="About Us"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
            <h2 className="topic-heading mb-4">About Us</h2>
            <p>
              knowledgebase2 was born out of the growing need to collect, sort,
              and allow community curation on the ocean of knowledge available
              on and off-line. Using knowledgebase2, modern learners can
              collaborate to accelerate the learning process for themselves and
              others.
            </p>
            <p>
              knowledgebase2 is an initiative run by Popular Demand, LLC and
              based in Denver, CO. It is 100% open source.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

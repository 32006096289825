import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import displayDataReducer from "./displayData/reducer";

// The Dev Tool extension for the browser
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  displayDataReducer,
  composeEnhancers(applyMiddleware(thunk))
);

import React from "react";

import { useSelector } from "react-redux";
import ContentRow from "./ContentRow";
import displayDataSelector from "../../store/displayData/selectors";

export default function MainContent() {
  const { designContent, techContent } = useSelector(displayDataSelector);

  return (
    <div className="container">
      <div className="topics-list">
        <div className="topic-row mb-5">
          <h2 className="topic-heading mb-4">Software Development</h2>

          <ContentRow data={techContent} />
        </div>
        <div className="topic-row">
          <h2 className="topic-heading mb-4">Design & User Experience</h2>

          <ContentRow data={designContent} />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import homeImage from "../images/home-bg.jpg";
import Navbar from "../brand/Navbar";
import Masthead from "../brand/Masthead";
import Footer from "../brand/Footer";
import MainContent from "../content/MainContent";

function App() {
  return (
    <div className="App">
      <Navbar />

      <Masthead
        description="Discover the learning resources that will help you reach to the next level."
        subhead="Crowdsourced Tech Training"
        background={homeImage}
      />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;

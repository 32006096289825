import content from "./content.js";

export const initialState = {
  content,
  loading: false,
  error: null,
};

export default function displayDataReducer(state = initialState, action) {
  return state;
}
